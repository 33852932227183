@font-face {
  font-family: LibreFranklin;
  src: url("LibreFranklin-VariableFont_wght.31945cc8.ttf");
}

html, body {
  background-color: #e7e8f6;
  margin: 0;
  padding: 0;
  font-family: LibreFranklin, sans-serif !important;
}
/*# sourceMappingURL=index.df7ddb71.css.map */
