@font-face {
  font-family: "LibreFranklin";
  src: url("./LibreFranklin-VariableFont_wght.ttf");
}

html,
body {
  font-family: 'LibreFranklin', sans-serif !important;
  padding: 0;
  margin: 0;
  background-color: rgb(231,232,246);
}